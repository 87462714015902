import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { useCart, useProducts } from "medusa-react";
import React, { useEffect } from "react";

import transformProductPreview from "@/lib/util/transform-product-preview";
import { ProductPreviewType } from "@/types/global";

interface ProductsLoaderProps {
  productIds: string[];
  children: (product: PricedProduct, preview: ProductPreviewType, index: number) => React.ReactElement;
  loading?: React.ReactNode;
  error?: React.ReactNode;
  onCompleted?: (products: PricedProduct[]) => void;
}

const ProductsLoader = (props: ProductsLoaderProps) => {
  const { cart } = useCart();
  const { isLoading, isError, products } = useProducts(
    { id: props.productIds, cart_id: cart?.id },
    { enabled: !!cart?.id && !!cart?.region && props.productIds.length > 0 },
  );

  useEffect(() => {
    if (!isLoading && !isError && props.onCompleted) {
      props.onCompleted(products ?? []);
    }
  }, [isLoading, isError, props, products]);

  if (isLoading) {
    return props.loading ?? null;
  }

  if (isError) {
    return props.error ?? null;
  }

  if (props.productIds.length === 0) {
    return null;
  }

  const ChildComponent = props.children;
  if (!ChildComponent || !products || !cart?.region) {
    return null;
  }

  return (
    <>
      {props.productIds.map((id, index) => {
        const product = products?.find((p) => p.id === id) ?? null;
        return product ? props.children(product, transformProductPreview(product, cart?.region), index) : null;
      })}
    </>
  );
};

export default ProductsLoader;
