import { useCart } from "medusa-react";
import React from "react";

import ProductCard from "@/components/Product/ProductCard";
import { ScrollContainer, ScrollItem } from "@/components/Scroll/Container";
import ProductCardSkeleton from "@/components/Skeleton/Product/Card";
import { StrapiProductInterface } from "@/interfaces/Api/Product/ProductInterface";
import { productCardClickEvent, productListViewEvent } from "@/lib/util/gtm";
import transformProductPreview from "@/lib/util/transform-product-preview";
import ProductsLoader from "@/modules/products/products-loader";
import { ProductPreviewType } from "@/types/global";

const RenderArticleCards = () => {
  const items = [];

  for (let i = 0; i < 10; i++) {
    items.push(
      <ScrollItem key={i}>
        <ProductCardSkeleton className="w-[10rem] lg:w-[25rem] p-3" />
      </ScrollItem>,
    );
  }

  return <>{items}</>;
};

export const ProductSlider = ({
  id,
  products,
  fromStrapi,
  isSlider = true,
}: {
  id: string | number;
  products?: ProductPreviewType[] | StrapiProductInterface[];
  fromStrapi?: boolean;
  isSlider?: boolean;
}) => {
  const { cart } = useCart();

  if (!products?.length) {
    return null;
  }

  let productIds: string[] = [];
  if (fromStrapi) {
    productIds = (products as StrapiProductInterface[])?.map((p) => p.attributes.medusa_id as string) ?? [];
  } else {
    productIds = (products as ProductPreviewType[])?.map((p) => p.id as string) ?? [];
  }

  return (
    <div className="mt-5">
      <ScrollContainer yOverflow={false} id={id} className="h-[10rem] lg:h-[32.5rem]">
        <ProductsLoader
          productIds={productIds}
          loading={<RenderArticleCards />}
          onCompleted={(products) => {
            if (cart?.region) {
              const productPreviews = products.map((p) => transformProductPreview(p, cart?.region));

              productListViewEvent({
                items: productPreviews,
                itemListId: `${id ?? "productSlider"}`,
                itemListName: `Produktslider ${id}`,
              });
            }
          }}
        >
          {(product, preview, index) => {
            const isFirst = index === 0;
            const isLast = index === products.length - 1;
            let key = `articleCard${(product as ProductPreviewType).id}`;
            if ((product as ProductPreviewType).preferred_variant) {
              key += `-${(product as ProductPreviewType).preferred_variant}`;
            }

            let className = "p-3";

            if (isFirst) {
              className = "py-3 pr-3";
            }

            if (isLast) {
              className = "py-3 pl-3";
            }

            return (
              <ScrollItem key={key}>
                <ProductCard
                  isSlider={!fromStrapi ? isSlider : true}
                  isProductList={true}
                  key={`scroll-product-item-card-${(product as ProductPreviewType).id}`}
                  className={`w-[10rem] lg:w-[20.5rem] ${!fromStrapi ? className : "px-3 lg:px-1"}`}
                  product={preview}
                  onClick={() => {
                    productCardClickEvent({
                      itemListId: `productSlider_${id}`,
                      itemListName: `Produktslider ${id}`,
                      items: [product as ProductPreviewType],
                      index: index,
                    });
                  }}
                />
              </ScrollItem>
            );
          }}
        </ProductsLoader>
      </ScrollContainer>
    </div>
  );
};
