"use client";
import clsx from "clsx";
import React, { useCallback, useRef, useState } from "react";

interface ReadMoreProps {
  content?: string;
  show?: string;
  hide?: string;
  hideAtTop?: boolean;
  hideAtBottom?: boolean;
  className?: string;
  contentEls?: React.JSX.Element[];
  elementsShown?: number;
  contentTag?: string;
  toggleInactiveClass?: string;
  toggleActiveClass?: string;
}

export default function ReadMore({
  content,
  show,
  hide,
  hideAtTop,
  hideAtBottom,
  className,
  contentEls,
  elementsShown,
  contentTag,
  toggleActiveClass = "",
  toggleInactiveClass = "",
}: ReadMoreProps) {
  const [showContent, setShowContent] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const toggleContent = useCallback(
    (state: boolean) => {
      if (!state) {
        containerRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }

      setShowContent(state);
    },
    [setShowContent],
  );

  const elementsToShow = elementsShown ?? 5;
  let showsAllContent = (contentEls?.length ?? elementsToShow) <= elementsToShow;

  if (contentEls === undefined && content !== undefined) {
    showsAllContent = false;
  }

  const contentElementType = contentTag ?? "p";

  return (
    <div className={clsx(className, "scroll-mt-64")} ref={containerRef}>
      {"undefined" !== typeof contentEls && <> {contentEls?.slice(0, elementsToShow)} </>}
      {(!showContent || (showContent && hideAtTop)) && !showsAllContent && (
        <span
          className={clsx("bold underline cursor-pointer", {
            [toggleInactiveClass]: !showContent,
            [toggleActiveClass]: showContent,
          })}
          onClick={() => setShowContent(!showContent)}
        >
          {!showContent ? (show ?? "Mehr lesen") : (hide ?? "weniger anzeigen")}
        </span>
      )}
      {showContent &&
        "undefined" !== typeof content &&
        React.createElement(contentElementType, { className: "text-gray-600", dangerouslySetInnerHTML: { __html: content } })}
      {showContent && "undefined" !== typeof contentEls && <> {contentEls?.slice(elementsShown ?? 5)} </>}
      {showContent && hideAtBottom && (
        <span className="bold underline cursor-pointer" onClick={() => toggleContent(false)}>
          {hide ?? "weniger anzeigen"}
        </span>
      )}
    </div>
  );
}
