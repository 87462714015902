import Link from "next/link";
import React from "react";

interface Props {
  title?: string;
  href?: string;
  outline?: boolean;
  dark?: boolean;
  active?: boolean;
  rounded?: boolean;
  results?: number | string;
  leftIcon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  testId?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

interface TitleProps {
  title?: string;
  results?: number | string;
  leftIcon?: React.ReactNode;
  children?: React.ReactNode;
}

const Title = ({ leftIcon, title, results, children }: TitleProps) => {
  return (
    <>
      {leftIcon}
      {title} {results ? <span>({results})</span> : <></>}
      {children}
    </>
  );
};

/**
 * @deprecated Use modules/common/components/button
 */
export default function Button({ title, href, outline, dark, active, rounded, results, leftIcon, children, className, onClick, type, disabled }: Props) {
  let classes = "";

  if (outline) {
    classes = "border border-gray-600 hover:bg-gray-300 pl-5 pr-5 bg-white";
  } else if (dark) {
    classes = "bg-zinc-500 hover:bg-pink-600 text-white pl-10 pr-10";
  } else if (active) {
    classes = "bg-pink-600 hover:bg-pink-700 text-white";
  } else {
    classes = "bg-gray-200 hover:bg-gray-300";
  }

  if (rounded) {
    classes += " rounded-full";
  }

  return (
    <div className={`focus:ring-0 focus:outline-none pl-4 pr-4 py-1 cursor-pointer ${classes} ${className}`} onClick={onClick}>
      {href ? (
        <Link href={href} className="inline-flex items-center cursor-pointer">
          <Title leftIcon={leftIcon} title={title} results={results}>
            {children}
          </Title>
        </Link>
      ) : (
        <button className="cursor-pointer" type={type} disabled={disabled}>
          <Title leftIcon={leftIcon} title={title} results={results}>
            {children}
          </Title>
        </button>
      )}
    </div>
  );
}
