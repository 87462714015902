"use client";

import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Button from "@/components/Form/Button/Button";
import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropLeftIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropLeftIcon";
import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";

interface ScrollContainerInterface {
  yOverflow: boolean;
  children: React.ReactNode;
  id?: string | number;
  height?: string;
  className?: string;
  containerClass?: string;
  fallback?: React.ReactNode;
}

export const ScrollItem = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={`c21__scroll__item ${className || ""}`}>{children}</div>;
};

const shouldScrollButtonsBeShown = (
  setShowLeftButton: (value: ((prevState: boolean) => boolean) | boolean) => void,
  el: HTMLDivElement,
  setShowRightButton: (value: ((prevState: boolean) => boolean) | boolean) => void,
  yOverflow: boolean,
) => {
  let position = el.scrollTop;
  let client = el.clientHeight;
  let scroll = el.scrollHeight;

  if (yOverflow) {
    position = el.scrollLeft;
    client = el.clientWidth;
    scroll = el.scrollWidth;
  }

  setShowLeftButton(position >= 10);
  setShowRightButton(position + client <= scroll - 10);
};

export const ScrollContainer = ({ yOverflow, children, id, height, className, containerClass, fallback }: ScrollContainerInterface) => {
  const [isShowLeftButton, setShowLeftButton] = useState<boolean>(false);
  const [isShowRightButton, setShowRightButton] = useState<boolean>(true);
  const container = useRef<HTMLDivElement | null>(null);

  const isFallback = useMemo(() => {
    return !children;
  }, [children]);

  const scrollY = (cont: HTMLElement, top: boolean) => {
    let scrollHeight = 200;
    const elements = cont.getElementsByClassName("c21__scroll__item");

    if (elements.length >= 1) {
      scrollHeight = elements[elements.length - 1].scrollHeight;
    }

    cont.scrollTop += top ? -scrollHeight : scrollHeight;
  };

  const scrollX = (cont: HTMLElement, left: boolean) => {
    let scrollWidthEl = 200;
    const elements = cont.getElementsByClassName("c21__scroll__item");

    if (elements.length >= 1) {
      scrollWidthEl = elements[elements.length - 1].scrollWidth;
    }

    cont.scrollLeft += left ? scrollWidthEl : -scrollWidthEl;
  };

  const scrollInContainerY = (top: boolean) => {
    container.current && scrollY(container.current, top);
  };

  const scrollInContainerX = (left: boolean) => {
    container.current && scrollX(container.current, left);
  };

  useEffect(() => {
    const el = container.current;
    if (el) {
      // on Scroll Events
      const eventListener = () => {
        shouldScrollButtonsBeShown(setShowLeftButton, el, setShowRightButton, !yOverflow);
      };

      // on Container Resize Events
      const handleResize = (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          if (entry.contentRect) {
            shouldScrollButtonsBeShown(setShowLeftButton, el, setShowRightButton, !yOverflow);
          }
        }
      };
      const resizeObserver = new ResizeObserver(handleResize);

      eventListener();
      el.addEventListener("scroll", eventListener);
      resizeObserver.observe(el);

      return () => {
        el.removeEventListener("scroll", eventListener);
        resizeObserver.unobserve(el);
      };
    }
  }, [yOverflow]);

  return (
    <div className="relative" id={id ? `scroll-Container-products-${id}` : undefined}>
      {yOverflow ? (
        <div className="absolute left-0 right-0">
          <Button
            className={clsx(
              `p-5 font-semibold text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border border-white`,
              "group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none",
              "w-10 h-10 m-auto text-center relative z-20",
              {
                invisible: !isShowLeftButton,
                block: isShowLeftButton,
              },
            )}
            rounded
            onClick={() => scrollInContainerY(true)}
          >
            <ArrowDropUpIcon width="30" height="30" className="relative right-3" fill="#000" />
          </Button>
        </div>
      ) : (
        <div className={`float-left relative ${className} ${!isShowLeftButton ? "hidden" : "block"}`} style={{ height: height }}>
          <div className="absolute left-0 top-1/2">
            <Button
              className={clsx(
                "p-5 font-semibold text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border border-white",
                "group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none",
                "w-10 h-10 m-auto text-center relative z-20",
              )}
              rounded
              onClick={() => scrollInContainerX(false)}
            >
              <ArrowDropLeftIcon width="30" height="30" className="relative right-3" fill="#000" />
            </Button>
          </div>
        </div>
      )}
      {!yOverflow && (
        <div
          className={clsx("float-right relative", className, {
            hidden: !isShowRightButton,
            block: isShowRightButton,
          })}
          style={{ height: height }}
        >
          <div style={{ position: "absolute", top: "50%", right: 0 }}>
            <Button
              className={clsx(
                "p-5 font-semibold text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border border-white",
                "group-focus:ring-4 group-focus:ring-white group-focus:outline-none w-10 h-10 m-auto text-center",
                "relative right-0.5 z-20",
              )}
              rounded
              onClick={() => scrollInContainerX(true)}
            >
              <ArrowDropRightIcon width="30" height="30" className="relative right-3" fill="#000" />
            </Button>
          </div>
        </div>
      )}
      <div
        ref={container}
        className={clsx(containerClass, {
          "overflow-y-scroll min-h-[42.5rem] max-h-[42.5rem] scrollbar-width": yOverflow,
          "overflow-x-scroll flex scrollbar-width": !yOverflow,
        })}
      >
        {isFallback && fallback ? fallback : children}
      </div>
      {yOverflow && (
        <div className="absolute left-0 right-0 bottom-0">
          <Button
            className={clsx(
              `p-5 font-semibold text-lg uppercase rounded-full bg-white/30 hover:bg-white/50 border border-white`,
              "group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none",
              "w-10 h-10 m-auto text-center relative z-20",
              {
                invisible: !isShowRightButton,
                block: isShowRightButton,
              },
            )}
            rounded
            onClick={() => scrollInContainerY(false)}
          >
            <ArrowDropDownIcon width="30" height="30" className="text-center relative right-3" fill="#000" />
          </Button>
        </div>
      )}
    </div>
  );
};
